.select_email_input {
  margin-bottom: 12px;
  input {
    margin-bottom: 8px;
  }
}
.auditWhitelistActive{
  display: flex;
  :global(.child-checkbox){
    margin-left: 28px;
  }
}
// .pkgUpdateActive{
//   display: flex;
//   align-items: center;
//   :global(.checkbox){
//     margin-right: 8px !important;
//     margin-top: -12px;
//   }
//   // :global(.child-checkbox){
//   //   margin-left: 28px;
//   // }
// }
.auditImageWhitelistActive{
  display: flex;
  // margin-bottom: 16px;
  margin-top: 2px;
  // :global(.child-checkbox){
  //   margin-left: 28px;
  // }; 
  // .wlContent{
  //   margin-left: 8px;
  // }
}
.auditWhitelistWrap{
  display: flex;
  flex-direction: column;
  margin: 2px 0;
  .wlContentWrap{
    margin: 4px 0 3px 24px;
    display: flex;
    flex-direction: column;
    .childCheckbox{
      margin-right: 16px;
    }
  } 
}
.auditImageWhitelistActive{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2px 0;
  // .wlContentWrap{
  //   display: flex;

  // }
  .wlContent{
    margin-left: 28px;
  }
   
}
.kbAdditionalParamWrap{
  display: flex;
  margin: 8px 0 0px 24px;
  // border: 1px solid #ccc;
  // border-radius: 5px;
  padding: 0px;
}
.pkgAdditionalParamWrap{
  display: flex;
  flex-direction: column;
  margin: 8px 0 12px 24px;
  // border: 1px solid #ccc;
  // border-radius: 5px;
  padding: 0px;
}
.tooltipWrap{
  display: flex;
}
.questionWrapper{
  margin-left: 8px;
  position: relative;
}
.kbUpdateFromWrap{
  // width: 260px;
  margin-left: 4px;
  margin-bottom: 8px;
}
.scopeWrap{
  // span {
  //   display: inline-block;
  //   margin-bottom: 8px;
  // }
  .titleWrap{
    display: flex;
    align-items: center;
    margin: 12px 0 12px 0;
    span{
      margin-right: 28px;
    }
  }
  .mutableBlockWrap{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 16px;
  }
}

.radioWrap {
  margin-bottom: 4px;
}
.timeValueWrap{
  margin: 8px 0 8px 36px;
}
.inputWithUseAssetTimezone{
  display: flex;
  align-items: center;
  gap: 20px;
}
.fileFormatTitle{
  margin-top: 16px;
}
.fileFormatCheckboxWrap{
  margin-bottom: 16px;
}
.ActionTaskDatePicker{
  margin: 16px 0;
}

.titleTaskWrapTooltip{
  display: flex;
  .tooltipWrap {
    margin-left: 8px;
    .contentExplaining{
      display: inline-block;
      color: #666666;
      margin-top: 2px;
      font-style: italic;
    }
  }
  .tooltipList{
    padding-left: 16px;
    margin-top: 4px;
    .itemTitle{
      font-weight: bold;
      margin-right: 4px;
    }
  }
}
.errorWrap{
  color: #ef664f;
}
.taskActionNotification{
  table{
    margin: 12px 4px 2px 4px;
    text-align: left;
    font-size: 10px;
    .col180px{
      width: 180px;
    }
    .col100px{
      width: 100px;
    }
    td{
      font-size: 14px !important;
      text-align: center;
    }
    th{
      text-align: center;
    }
    .tdTitle{
      font-size: 10px !important;
      text-align: left;
    }
    .tdTitleWeight{
      font-weight: 700;
      font-size: 10px !important;
      text-align: left;
    }
  }
  table, td, th {
    border-collapse: collapse;
    border: 1px solid #dedfe0;
    padding: 4px 12px;
  }
  th{
    padding: 2px 10px;
  }
}
.selectedSrvTable {
  td,
  th {
    height: 36px;
    box-sizing: border-box;
    padding: 0;
    button {
      padding: 0;
    }
  }
  tr:last-child td {
    border: none;
  }
  .delete_button_th {
    //   background-color: red;
    width: 40px;
  }
  .delete_button {
    color: #44c74f;
  }
}
.delete_button_th {
  width: 40px;
}
.delete_button {
  color: #44c74f;
}
.add_button { 
  color: #44c74f;
}
.add_button_select{
  color: #424242;
  // :global(.k-button-icon) {
  //   color: #424242 !important;  
  // }
}
.srvEmptyCred{
  color: #e91e63;
}
.ldapGrWrap{
  margin-bottom: 20px !important;
  .ldapGrAction{
    display: flex;
    align-items: center;
    span{
      margin-right: 12px;
    }
  }
  .ldapGrContent{
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 16px;
    display: flex;
    min-height: 40px;
    max-height: 100px;
    overflow: auto;
    flex-wrap: wrap;
    button{
      font-size: 12px !important;
    }
    
  }
}
.policyLdapBlockTask{
  // margin-left: 8px !important;
  margin: -12px 0 16px 8px !important;
  overflow: auto;
  max-height: 130px;
  .formCredentialTable {
    td,
    th {
      height: 32px;
      box-sizing: border-box;
      padding: 0;
      font-size: 12px;
      text-transform: none;
      overflow: hidden;
      text-overflow: ellipsis;
      button {
        padding: 0;
      }
    }
  }
}
.ldapGrListModal{
  :global(.k-link) {
    cursor: default;    
  }
  :global(.k-window){
    padding: 24px 40px !important; 
  }
  .description{
    color: #666666;
  }
  .searchLdapGrWrap{
    margin-top: 24px;
    width: 600px;
  }
  .errorResponseWrap{
    margin-top: 24px;
    color: #ef664f;
    .message{
      margin-left: 8px;
    }
  }
}
.isDuplicateWrap{
  margin: 16px 0 16px 12px;
}
.applyCreds{
  .applyCredsWrap{
    margin-left: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px 0px 8px 16px;
    .applyCredsRow{
      display: flex;
      align-items: center;
      max-width: 380px;
      justify-content: space-between;
      margin-bottom: 4px;
      .applyCredsElementContent{
        display: flex;
        max-width: 220px;
        .applyCredsElementNameWrap{          
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    // .applyCredsSrv{
    //   display: flex;
    //   align-items: center;
    //   span.applyCredsElementTitle{
    //     margin-right: 26px;
    //   }
    //   span.contenAuth{
    //     margin-left: 16px;
    //   }
    //   margin-bottom: 4px;
    // }
    // .applyCredsHostSsh{
    //   display: flex;
    //   align-items: center;
    //   span.applyCredsElementTitle{
    //     margin-right: 32px;
    //   }
    //   span.contenAuth{
    //     margin-left: 16px;
    //   }
    //   margin-bottom: 4px;
    // }
    // .applyCredsHostWinrm{
    //   display: flex;
    //   align-items: center;
    //   span.applyCredsElementTitle{
    //     margin-right: 10px;
    //   }
    //   span.contenAuth{
    //     margin-left: 16px;
    //   }
    //   margin-bottom: 4px;   
    // }
    .btnCred{
      padding: 4px 4px;
    }
    .dropdownContent{
      left: 12px;
      top: 4px;
      button{
        font-size: 12px;
      }
    }
}
  margin-bottom: 20px !important;
}
.credentials_button {
  justify-content: left;
}
:global(.task-add-edit) {
  :global(.form-content){
    min-height: 512px;
  }
}
:global(.apply-assets){
  .taskWinUpdateActionWrap{
    display: flex;
    align-items: center;
  }
  .addAssetScope{
    margin-left: 28px;
  }
}
.taskSystemRebootValue{
  margin: 8px 0 0 28px;
  max-width: 564px;
}



.credentialWrap {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
}

.cronTaskWrap{
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  :global(.cron-form-label){
    margin-bottom: 8px;
  }
  // :global(.newtask__description){
  //   margin: 4px 0 0 16px;
  // }
}
.jiraProjectIdsLabelWrap{
  position: relative;
  display: flex;
  margin-bottom: -14px !important;
  margin-top: 16px;
}

.jiraMetaInfo{
  font-size: 12px;
  color: #666666;
}

// .cellNameTicketSystemWrap{
//   display: flex;
//   justify-content: space-between;
//   .defaultInfo{
//     display: inline-block;
//     margin-left: 8px;
//     color: #9a9595; 
//   }
// }
.cellNameTicketSystemNameBold{
  font-weight: bold;;
}
.loaderWrap{
  display: flex;
  align-items: center;
  width: 100%;
  .loaderInfo{
    margin-left: 12px;
  }
}
.loaderInline{
  color: black;
  margin: 16px 0 14px 94px;
}
.errorJiraMetaRequestWrap{
  margin: 32px 0 !important;
}
.jiraMetaSuccessWrap{
  margin: 16px 0 32px 0 !important;
  .spanCheck{
    color: green;
    font-weight: bold;
  }
  .spanContent{
    display: inline-block;
    margin-left: 8px;
  }
}

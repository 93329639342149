@import '~@progress/kendo-theme-default/dist/all.scss';
@import 'vars';

// Verdana
@font-face {
  font-family: 'Verdana';
  src: url('../public/fonts/Verdana.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: Verdana;
  src: url('../public/fonts/Verdana-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: Verdana;
  src: url('../public/fonts/Verdana-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: Verdana;
  src: url('../public/fonts/Verdana-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}

// Roboto
@font-face {
  font-family: Roboto;
  src: url('../public/fonts/Roboto-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: lighter;
}
@font-face {
  font-family: Roboto;
  src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: Roboto;
  src: url('../public/fonts/Roboto-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: Roboto;
  src: url('../public/fonts/Roboto-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: Roboto;
  src: url('../public/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}

// Noto Sans
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-ExtraLight.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-ExtraBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-ThinItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-ExtraLightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-SemiBoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-ExtraBoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: NotoSans;
  src: url('../public/fonts/NotoSans-BlackItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 900;
}

body {
  // font-family: Verdana;
  font-family: NotoSans, Arial, sans-serif;
  > .k-animation-container-shown {
    // background: red;
    .k-popup {
      border-radius: 4px;
    }
    .k-link {
      padding: 8px 16px;
    }
  }
  // font-size: 14px;
  font-size: 13px;
}

.difference-modal a[target="_blank"]::after{
  background: url("../public/images/new_tab.svg");
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  margin-top: 2px;
}

a,
.k-grid a {
  color: $main-text-color;
  text-decoration: underline;
  font-weight: 400;
  line-height: 125%;
  font-style: normal;
  cursor: pointer;
  &:hover {
    // text-decoration: underline;
    color: black;
    text-decoration: underline;
  }
}

select,
input,
.k-input,
.k-dropdownlist {
  color: #333;
  // font-size: 14px;
  font-size: 13px;
  line-height: 140%;
  max-width: 588px;
  // height: 34px;
  // padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  &.search-input,
  &.search-taskname,
  &.custom-search {
    // font-size: 14px;
    font-size: 13px;
  }
  &:disabled{
    color: #999;
  }
}
.k-input.k-textarea{
  height: auto;
}
.copied-container {
  .k-notification-container {
    background-color: white;
    .k-widget {
      border: none;
    }
    .k-notification {
      background-color: $dark-grey;
      color: white;
      opacity: 0.3;
      padding: 8px 12px;
      .k-notification-wrap {
        align-items: center;
      }
    }
  }
}

.v-icon {
  display: inline-flex;
  // align-items: center;
  // justify-content: center;
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  mask-size: 100%;
  mask-position: center;
  mask-repeat: no-repeat;
}

.refresh-icon {
  background-image: url('../public/images/refresh.svg');
}
.hide-passwd-icon {
  background-image: url('../public/images/hide_password.svg');
}
.show-passwd-icon {
  background-image: url('../public/images/show_password.svg');
}

input.k-input {
  box-sizing: border-box;
  // border-radius: 5px;
  height: 34px;
  padding: 0 16px;
}

.checkbox.disabled{
  cursor: default;
}

.k-checkbox {
  border-radius: 3px;
  // border-color: #ccc;
  border-color: rgba(0, 0, 0, 0.38);
  &:checked {
    border-color: $button-green-color;
    background-color: $button-green-color;
  }
}
.k-checkbox + .k-checkbox-label {
  margin-inline-start: 12px;
  // font-size: 16px;
}
.k-radio {
  margin: 0 2px;
  width: 18px;
  height: 18px;
  appearance: none;
  -webkit-appearance: none;
  border: 0.2rem solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.38);
  background-color: #fff;
  &:checked, .k-checked {
    box-shadow: 0 0 0 1px $button-green-color;
    background-color: $button-green-color;
    border-width: 0.2rem !important;
    border-color: white;
    background-image: none;
    &:focus{
      box-shadow: 0 0 0 1px $button-green-color;
    }
  }
  &:hover{
    border-width: 0;
    &:not(:checked){
      background-color: #ddd;
    }
  }
  &:disabled{
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.38);
    border: none;
    background-color: #eee;
  }
  &:focus{
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.40);
  }
}
.k-radio + .k-radio-label {
  margin-inline-start: 12px;
}

.k-dropdownlist {
  padding: 8px 4px 8px 16px;
  background-color: inherit;
  &.k-picker {
    min-width: 80px;
  }
  .k-input-inner {
    padding: 0;
  }
  .k-input-button {
    padding: 0;
  }
  .k-button-icon {
    mask-image: url('../public/images/down_arrow_btn.svg');
    // position: absolute;
    // width: 20px;
    // height: 20px;
    background-color: $button-green-color;
    mask-position: center;
    mask-repeat: no-repeat;
    &::before {
      content: '';
    }
  }
}

// select,
// .k-dropdownlist {
//   height: 38px;
//   padding: 8px 16px;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   -ms-appearance: none;
//   appearance: none;
//   // &::after {
//   background-image: url('../public/images/down_arrow_btn.svg');
//   background-repeat: no-repeat;
//   background-position: right;
//   // width: 20px;
//   // height: 20px;
//   // }
// }

.k-drawer-content {
  width: 100%;
  position: relative;
  min-height: 80vh;
}

.k-form-buttons,
.k-form-select-buttons {
  display: flex;
  gap: 0 16px;
  margin-top: 12px;
}

.maintenance-windows-modal .k-form-buttons{
  margin-top: 28px;
}

.hostdescription, .ticketdescription {
  .common-summary-page{
    grid-gap: 0 30px;
  }  
}

.common-summary-page {
  padding: 16px 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  grid-gap: 0 60px;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 24px;
  // overflow-x: auto;
}

.tab-meta-block {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 28px;
  .json-actions {
    .k-menu {
      border: none;
      padding: 0;
    }
    .k-link {
      // font-size: 14px;
      font-size: 13px;
      color: $text-green-color;
    }
    .k-icon {
      background: $text-green-color;
    }
  }
}

table img {
  max-height: 20px;
}

.k-tilelayout {
  background-color: inherit;
  padding: 0 !important;
  .k-card {
    border-radius: 5px;
  }
  .k-card-header {
    background: #f2f2f2;
    padding: 16px 24px;
    border-bottom: none;
    text-transform: uppercase;
    .k-card-title { 
      font-size: 14px 
    }
  }
  .k-card-body {
    padding-top: 0;
  }
  .table.horizontal {
    margin-top: 0;
  }
}

table.horizontal {
  border-spacing: 0;
  color: #666;
  text-align: left;
  table-layout: fixed;
  width: 100%;
  th,
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  th {
    color: $main-text-color;
    font-weight: normal;
    text-transform: uppercase;
    padding: 16px 12px;
    border-bottom: 1px solid #ccc;
  }
  td {
    padding: 12px;
    // font-size: 15px;
    // font-size: 14px;
    font-size: 13px;
    border-bottom: 1px solid #ededed;
    height: 44px;
    // vertical-align: m
  }
}

table.column {
  color: #666;
  // font-size: 14px;
  font-size: 13px;
  // border-spacing: 0 8px;
  border-spacing: 0 4px;
  // display: inline-block;
  & > tbody > tr {
    vertical-align: baseline;
    & > td {
      height: 18px;
      &:first-child {
        color: #333;
        padding-right: 16px;
        &:after {
          content: ':';
        }
      }
      &:last-child {
        white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
  }
}

// @media screen and (max-width: 1600px) {
//   .k-grid table {
//     table-layout: auto;
//   }
// }

td.number {
  text-align: right;
}

.k-grid:not(.no-margin-top) table {
  margin-top: 20px;
}
.k-grid,
.k-grid-flow-col {
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  border: none;
  position: static;
  table:not(.column) {
    border: none;
    box-sizing: border-box;
    width: 100%;
    color: $table-text-color;
    table-layout: fixed;
    th {
      color: $table-header-color;
      // padding: 16px 24px;
      // padding: 16px 12px; like design
      padding: 12px; // like new
      font-size: 12px; // like new
      // font-size: 14px; like design
      font-weight: 600; // like new
      line-height: 140%;
      text-transform: uppercase;
      border-bottom: 1px solid #ccc !important;
    }
    td {
      // padding: 12px 24px;
      // padding: 12px; like design
      padding: 0 12px; // like new
      overflow: hidden;
      // height: 44px; like design
      height: 40px; // like new
      box-sizing: border-box;
      // font-size: 15px;
      // font-size: 14px;
      font-size: 13px;
      border-bottom: 1px solid #ededed !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    th,
    td {
      border: none;
      border-right: 0px !important;
    }
    tr {
      // height: 44px; like design
      height: 40px; // like new
      white-space: nowrap;
      overflow: hidden;
    }
    img {
      max-height: 20px;
    }
    td.checkbox_cell span {
      display: flex;
      align-items: center;
    }
    .cell_os {
      display: flex;
      align-items: center;
      position: relative;
      //   top: 4px;
      img {
        width: 20px;
        margin-right: 8px;
        filter: opacity(0.6);
      }
    }
    .cell_menu {
      overflow: visible;
      .dropdown__content {
        right: 16px;
        top: -20px;
        button {
          border: none;
        }
      }
    }
  }
  // .k-header {
  //   position: static;
  // }
  .k-i-sort-desc-sm,
  .k-i-sort-asc-sm {
    color: $table-header-color;
  }
  .k-i-sort-desc-sm::before {
    content: '\e015';
  }
  .k-i-sort-asc-sm::before {
    content: '\e013';
  }
}

button,
.k-button,
.k-menu {
  background: none;
  color: $button-text-color;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 8px 16px;
  // font-size: 14px;
  font-size: 13px;
  transition: background 0.5s;
  max-height: 34px;
  &:hover:not(:disabled) {
    cursor: pointer;
    background: #ebebeb;
    transition: background 0.5s;
    text-decoration: none;
    // color: inherit;
  }
  // &.k-menu {
  //   max-height: 35px;
  // }
  span {
    display: flex;
    align-items: center;
    // img {
    //   margin-right: 8px;
    //   margin-left: -8px;
    // }
  }
  &.first-icon {
    padding-left: 16px;
    span {
      gap: 8px;
    }
  }
  .k-item {
    display: flex;
    align-self: center;
  }
  .k-menu-link-text{
    font-size: 14px;
  }
}

.topactions div:not(.action) .k-menu {
  .k-menu-link-text {
    color: $button-text-color;
  }
  .k-icon {
    background: $button-text-color;
  }
}
.modal-topactions {
  margin-bottom: 16px;
}

.dropdown__container {
  position: relative;
  &:hover .dropdown__content {
    display: flex;
    flex-direction: column;
  }
  .dropdown__content {
    display: none;
    position: absolute;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: rgb(85, 85, 85);
    z-index: 99;
    background: white;
    overflow: hidden;
    .k-button {
      padding: 8px 16px;
      width: 100%;
      border-radius: 0;
    }
  }
}

.topactions {
  display: flex;
  align-items: center;
  // button + button,
  // div + button {
  //   margin-left: 16px;
  // }
  gap: 0 16px;
  .k-menu {
    margin: 0;
  }
  .action .k-icon {
    background: white;
  }
}

.arrow_down {
  padding: 4px;
}

.k-drawer-start {
  background: #ededed;
  //   height: 100vh;
  // font-size: 14px;
  font-size: 13px;
  font-weight: 400px;
  font-style: normal;
  min-height: calc(100vh - 48px);
  .k-drawer-wrapper {
    margin-top: 24px;
    .k-drawer-items {
      margin: 0;
      list-style: none;
      padding-left: 24px;
      .head {
        color: #999;
        line-height: 140%;
        text-transform: uppercase;
        padding: 4px 0;
      }
      .button {
        color: #333;
        // font-size: 14px;
        font-size: 13px;
        padding: 6px 8px;
        line-height: 125%;
      }
      .k-state-selected {
        color: $menu-selected-color;
        & a {
          color: $menu-selected-color;;
        }
        & .k-icon {
          background: $menu-selected-color;
        }
      }
    }
  }
}

// .k-drawer-container {
//   position: fixed;
//   width: 100%;
//   height: 100%;
// }

.k-drawer-container .k-drawer-items .k-icon {
  font-size: 20px;
  margin-right: 16px;
  width: 15px;
  height: 15px;
  background-color: #333;
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
  mask-position: center;
}

.k-drawer-item {
  align-items: center;
  user-select: none;
  display: flex;
}

.k-drawer-content {
  //   padding-left: 32px;
  padding: 0 32px 32px;
  .common-header-page {
    margin: 28px 0 24px;
    h3 {
      margin: 0 0 20px;
      font-size: 28px;
      line-height: 125%;
      font-weight: 700;
      color: #333;
    }
    .button-group {
      display: flex;
      .k-button,
      .k-menu {
        margin: 0;
      }
      > {
        :first-child:not(:last-child),
        :first-child:not(:last-child) > .k-menu{
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        :last-child:not(:first-child), 
        :last-child:not(:first-child) > .k-menu{
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        :not(:first-child):not(:last-child){
          border-radius: 0;
          border-left:  none;
        }
      }
      // > {
      //   .button:first-child:not(:last-child), 
      //   .btn-menu:first-child:not(:last-child) > .k.menu{
      //     border-top-right-radius: 0;
      //     border-bottom-right-radius: 0;
      //   }
      // }
      // > {
      //   button:first-child,
      //   .btn-menu:first-child > .k-menu {
      //     border-top-right-radius: 0;
      //     border-bottom-right-radius: 0;
      //   }
      //   button:not(:first-child):not(:last-child),
      //   .btn-menu:not(:first-child):not(:last-child) > .k-menu {
      //     border-left: none;
      //     border-radius: 0;
      //   }
      //   button:last-child,
      //   .btn-menu:last-child > .k-menu {
      //     border-left: none;
      //     border-top-left-radius: 0;
      //     border-bottom-left-radius: 0;
      //   }
      // }
      // > * {
      //   border-radius: 0;
      // }
      // > *:last-child {
      //   &.k-button,
      //   .k-menu {
      //     border-left: none;
      //     border-top-left-radius: 0;
      //     border-bottom-left-radius: 0;
      //   }
      // }
    }
    // .k-button + .button-group,
    // .button-group + .button-group {
    //   margin-left: 24px;
    // }
    // .edit-group button {
    //   color: #999;
    // }
    .edit-group .k-icon {
      background: #999;
    }
    &__title{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
  }
}

.serverdescription {
  .common-header-page__title {
    margin: 24px 0 12px;
    h3 {
      // margin: 0 0 20px;
      font-size: 28px;
      line-height: 125%;
      font-weight: 700;
      color: #333;
    }
  }
  .topactions{
    margin: -8px 0 20px 0;
  }
}

.k-tabstrip.asset-tabstrip {
  .k-tabstrip-items {
    border: 1px solid $border-color;
    border-radius: 50px;
    padding: 4px;
    .k-item {
      border-radius: 50px;
      padding: 4px 8px;
      border: none;
      color: $button-green-color;
      &.k-state-active {
        background: $button-green-color;
        color: $white;
      }
    }
  }
  .k-state-active {
    border: none;
    padding: 32px 0 0 0;
  }
}

.k-tabstrip.diff-tabstrip {
  .k-tabstrip-items {
    border: 1px solid $border-color;
    border-radius: 50px;
    padding: 4px;
    .k-item {
      border-radius: 50px;
      padding: 4px 8px;
      border: none;
      color: $button-green-color;
      background-color: transparent;
      &.k-state-active {
        background: $button-green-color;
        color: $white;
      }
    }
  }
  .k-state-active {
    border: none;
    padding: 32px 0 0 0;
  }
}

.form-content {
  select,
  input,
  .k-input,
  .k-dropdownlist,
  span:has(input) {
    max-width: inherit;
  }
  .k-animation-container {
    width: 100%;
    font-size: 13px;
    .form-field-wrapper:not(input){
      margin: 16px 0 !important;
    }
  }
}

.k-stepper {
  &.linux-install-agent-stepper,
  &.windows-install-agent-stepper {
    grid-template-rows: repeat(10, 1fr) !important;
  }
  &.linux-install-agent-stepper {
    .k-step-list {
      li:first-child {
        height: 80px;
      }
      li:nth-child(2) {
        height: 180px;
      }
      li:nth-child(3) {
        display: none;
      }
      li:nth-child(4) {
        height: 92px;
      }
      li:nth-child(5) {
        height: 100px;
      }
    }
    .k-progressbar {
      top: -28px;
      height: 360px;
    }
  }
  &.windows-install-agent-stepper {
    .k-step-list {
      li:first-child{
        height: 80px;
      }
      li:nth-child(2) {
        height: 84px;
      }
      li:nth-child(3) {
        height: 80px;
      }
      li:nth-child(4) {
        height: 80px;
      }
    }
    .k-progressbar {
      top: -28px;
    }
  }
  &.sensor-install-stepper {
    .k-step-list {
      li:first-child {
        height: 76px;
      }
      li:nth-child(2) {
        height: 168px;
      }
      li:nth-child(3) {
        display: none;
      }
      li:nth-child(4) {
        height: 92px;
      }
    }
    .k-progressbar {
      top: -16px;
      height: 340px;
    }
  }
  &.credential-stepper-win {
    .k-step-list {
      li:first-child {
        height: 168px;
      }
      li:nth-child(2) {
        height: 190px;
      }
    }
    .k-progressbar {
      top: -24px;
      height: 178px;
    }
  }

  &.credential-stepper {
    .k-step-list {
      li:first-child {
        height: 100px;
      }
      li:nth-child(2) {
        height: 120px;
      }
    }
    .k-progressbar {
      top: -24px;
      height: 112px;
    }
  }

  .k-step-current,
  .k-step-done {
    .k-step-indicator {
      border-color: $button-green-color;
      background-color: $button-green-color;
      &:hover {
        background: $button-green-color;
        filter: brightness(90%);
      }
    }
    &:hover {
      .k-step-indicator {
        background-color: $button-green-color;
      }
    }
  }
  .k-progressbar .k-state-selected {
    background-color: $button-green-color;
  }
}

.scope-notification {
  .k-notification {
    overflow-y: scroll;
    max-height: 200px;
  }
  .k-i-none {
    display: none;
  }
}

.k-tabstrip:not(.asset-tabstrip):not(.diff-tabstrip) {
  .k-tabstrip-items {
    background: #ededed;
    border-radius: 8px 8px 0 0;
    // display: flex;
    // align-items: end;
    display: block;
    height: 38px;
    .k-item:not(.k-state-active) {
      height: 38px;
      background: #ededed;
      // border-bottom: 1px solid #ccc;
      // border-radius: 0;
    }
    .k-link {
      padding: 0;
      position: relative;
      // top: -4px;
    }
  }

  .k-item.k-state-active {
    height: 40px;
    top: -1px;
    border-color: #ccc;
    border-bottom-color: transparent;
    font-weight: 700;
    border-radius: 5px 5px 0 0;
    color: #333;
    // font-weight: bold;
  }
  .k-item:not(.k-menu-item) {
    box-sizing: border-box;
    padding: 8px 20px;
    display: inline-block;
    // font-size: 14px;
    font-size: 13px;
    color: #666;
    // border: 1px solid #ededed;
    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }
  .k-content.k-state-active {
    border: 1px solid #ccc !important;
    border-top-color: #ccc;
    padding: 24px 0;
    border-radius: 0 8px 8px 8px;
    .k-animation-container {
      width: 100%;
      .k-child-animation-container > * > * {
        &:not(.k-tabstrip) {
          // margin: auto 24px;
          margin-left: 24px;
          margin-right: 24px;
        }
        &.k-tabstrip {
          padding: 0;
          width: calc(100% + 2px);
          left: -1px;
          position: relative;

          .k-tabstrip-items-wrapper {
            padding: 0 24px;
          }
          .k-state-active {
            border-radius: 8px 8px 0 0;
            border-bottom: none !important;
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .k-item:not(.k-state-active) + .k-item:not(.k-state-active) {
    &::before {
      content: '';
      background: #ccc;
      width: 1px;
      height: 21px;
      position: absolute;
      top: 8px;
      left: 0;
    }
  }
}
.k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-color: #ccc;
}
.k-tabstrip-top > .k-tabstrip-items-wrapper {
  margin-bottom: 0;
  border-bottom: none;
}

.k-icon {
  -moz-background-size: 100%; /* Firefox 3.6+ */
  -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
  -o-background-size: 100%; /* Opera 9.6+ */
  background-size: 100%;
  &.success{
    color: #00850b;
  }
  &.fail{
    color: #f20c0c;
  }
}

.k-menu-group .k-item > .k-link {
  transition: background 0.5s;
  &:hover {
    transition: background 0.5s;
  }
  &:active{
    background-color: #44c74f;
  }
}

.k-switch{
  &.k-switch-on .k-switch-track,
  &.k-switch-off .k-switch-track{
    background-color: #f0f0f0;
    border-color: rgba(0, 0, 0, 0.16);
  }
  &.k-switch-on .k-switch-thumb, 
  &.k-switch-off .k-switch-thumb{
    border-color: rgba(0, 0, 0, 0.2);
  }
  &.k-switch-on .k-switch-track{
    background-color: #44c74f;
    border-color: #a3bba5;
  }
  &.k-switch-sm{
    width: 40px;
    .k-switch-track{
      width: 40px;
      height: 20px;
    }
    &.k-switch-on .k-switch-thumb-wrap{
      left: calc(100% - 12px);
    }
  }
}

.tree-line-icon{
  mask-image: url('../public/images/vertical_line.svg');
  background-color: #ccc;
}
.tree-line-horizontal-icon{
  mask-image: url('../public/images/horizontal_line.svg');
  background-color: #ccc;
}

.vulnerability-dashboard-menu-icon {
  mask-image: url('../public/images/left_menu_icons/vulnerability-dashboard-menu-icon.svg');
}
.inventorization-dashboard-menu-icon {
  mask-image: url('../public/images/left_menu_icons/inventorization-dashboard-menu-icon.svg');
}
.hosts-menu-icon {
  mask-image: url('../public/images/left_menu_icons/hosts-menu-icon.svg');
}
.sensors-menu-icon {
  mask-image: url('../public/images/left_menu_icons/sensors-menu-icon.svg');
}
.images-menu-icon {
  mask-image: url('../public/images/left_menu_icons/images-menu-icon.svg');
}
.host-groups-menu-icon {
  mask-image: url('../public/images/left_menu_icons/host-groups-menu-icon.svg');
}
.devices-menu-icon {
  mask-image: url('../public/images/left_menu_icons/devices-menu-icon.svg');
}
.tasks-menu-icon {
  mask-image: url('../public/images/left_menu_icons/tasks-menu-icon.svg');
}
.ticket-menu-icon {
  mask-image: url('../public/images/left_menu_icons/ticket-menu-icon.svg');
}
.ticket-system-menu-icon {
  mask-image: url('../public/images/left_menu_icons/ticket-system-menu-icon.svg');
}
.history-menu-icon {
  mask-image: url('../public/images/left_menu_icons/history-menu-icon.svg');
}
.vulns-menu-icon {
  mask-image: url('../public/images/left_menu_icons/vulns-menu-icon.svg');
}
.vdb-menu-icon{
  mask-image: url('../public/images/left_menu_icons/vdb-menu-icon.svg');
}
.settings-menu-icon {
  mask-image: url('../public/images/left_menu_icons/settings-menu-icon.svg');
}
.users-menu-icon {
  mask-image: url('../public/images/left_menu_icons/users-menu-icon.svg');
}
.logs-menu-icon {
  mask-image: url('../public/images/left_menu_icons/logs-menu-icon.svg');
}
.credentials-menu-icon {
  mask-image: url('../public/images/left_menu_icons/credentials-menu-icon.svg');
}
.software-list-menu-icon {
  mask-image: url('../public/images/left_menu_icons/software-list-menu-icon.svg');
}
.software-management-menu-icon {
  mask-image: url('../public/images/approve-icon.svg');
}
.help-menu-icon {
  mask-image: url('../public/images/left_menu_icons/help-menu-icon.svg');
}
.edit-icon {
  mask-image: url('../public/images/edit.svg');
}
.repeat-icon {
  mask-image: url('../public/images/repeat.svg');
}
.delete-icon {
  mask-image: url('../public/images/delete.svg');
}
.drop-icon {
  mask-image: url('../public/images/drop.svg');
}
.filterTag-icon {
  mask-image: url('../public/images/filterTag.svg');
}
.graphic-icon {
  background-image: url('../public/images/graphic-icon.svg');
}
.sort-desc-icon {
  background-image: url('../public/images/sort-asc.svg');
}
.sort-asc-icon {
  background-image: url('../public/images/sort-desc.svg');
}
.no-sort-icon {
  background-image: url('../public/images/no-sort.svg');
}
.graphic-icon {
  background-image: url('../public/images/graphic-icon.svg');
}
.scales-icon{
  mask-image: url('../public/images/scales.svg');
}

.new-tab-icon{
  mask-image: url('../public/images/new_tab.svg');
}

.reboot-icon{
  background: url('../public/images/restart.svg');
}

.agent-status {
  display: flex;
  align-items: center;
  > span {
    mask-image: url('../public/images/agent_status_icon.svg');
    background-color: #666;
    display: inline-block;
    align-items: center;
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
}
.credential-icon {
  text-transform: uppercase;
  font-size: 9px;
  font-weight: bold;
  border: 0.8px solid #ededed;
  padding: 3.2px 4.8px;
  margin: 0 0 0 16px;
}

button.action,
.action > .k-menu {
  border: none;
  border-radius: 8px;
  background: $button-green-color;
  color: #fff;
  border: 1px solid $button-green-color;
  transition: color 0.5s, background 0.5s;
  &:hover {
    background: white !important;
    border: 1px solid $button-green-color;
    color: $button-green-color;
    transition: color 0.5s, background 0.5s;
    .k-icon {
      background: $button-green-color;
      transition: background 0.5s;
    }
  }
}

.credential-block-content {
  display: flex;
  .credential-block {
    display: flex;
  }
}

// .audit__vulns button {
//   color: #44c74f;
//   font-size: 14px;
//   padding: 0;
//   border: none;
// }

.modal-button {
  color: $main-text-color;
  // font-size: 14px;
  font-size: 13px;
  padding: 0;
  border: none;
  text-decoration: underline;
  text-decoration-style: dashed;
  &:hover {
    color: black;
  }
}

.excel-export {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 60px;
}

.wrapper-menu {
  .row {
    padding: 12px 0;
    display: flex;
    align-items: center;
    button {
      padding: 0;
      border: none;
      background: #ededed;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      &.active {
        background: #44c74f;
        span {
          color: white;
          transition: color 0.5s;
        }
      }
    }
    .k-button {
      padding: 0;
    }
    .row-title {
      color: #333;
      font-size: 16px;
      font-weight: 700;
      // padding: 0 16px;
      padding: 0 8px;
      margin-left: 8px;
      &.all-new{
        background-color: rgba(0, 180, 0, 0.1) !important;
      }
      &.all-closed{
        background-color: rgba(255, 0, 0, 0.15) !important;
      }
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-left: 9px;
    padding: 0 0 0 28px;
    border-left: 1px solid #ccc;
    grid-gap: 0 60px;
    &:not(.visible) {
      display: none;
    }
    .break {
      flex-basis: 100%;
      height: 0;
    }
    // .k-grid {
    //   overflow-x: unset;
    // }
  }
}

.k-notification {
  border-radius: 5px;
}
.k-notification-group {
  z-index: 11000;
  .k-animation-container.k-animation-container-relative{
    text-align: right;
  }
}

.k-dialog-wrapper {
  left: auto;
  right: 0;
  top: 0;
  height: 100%;
  // max-width: calc(100% - 64px);
  max-width: 1500px;
  width: calc(100vw - 60px);
  align-items: flex-end;
  z-index: 204;
  .k-window-titlebar {
    margin: 0 0 30px 0;
  }
  .k-window-title {
    padding: 0;
  }
  &.small {
    width: 700px;
    z-index: 205;
  }
  &.smallest {
    width: 640px;
    z-index: 206;
  }
  &.biggest{
    max-width: 1660px;
    z-index: 203;
  }
  &.middle{
    max-width: 1300px;
  }

  &.small,
  &.smallest {
    .k-form > .form-content > *:not(.k-form-buttons) {
      margin: 16px 0;
    }
  }
  .k-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    .k-label {
      margin-bottom: 8px;
    }
    .form-content {
      overflow-y: auto;
    }
  }

  .apply-assets,
  .apply-assets-group,
  .select-email {
    .title-apply-assets,
    .title-select-email {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0 8px;
      a {
        margin-left: 12px;
        padding: 4px 24px;
      }
    }
    .list-assets {
      padding: 12px;
      border: 1px solid #ccc;
      overflow: auto;
      max-height: 240px !important;
      min-height: 60px !important;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 4px 12px;
    }
    .small-list-assets {
      min-height: 24px !important;
    }
  }
  .apply-assets-group, .apply-assets{
    margin-top: 24px;
  }
  .k-window {
    height: 100%;
    width: 100%;
    padding: 32px 32px;
    // .k-window-actions .k-button {
    //   top: 0;
    // }
    .k-window-titlebar {
      background: inherit;
      border: none;
      color: $main-text-color;
      padding: 0;
      align-items: flex-start;
      .k-window-title {
        font-size: 28px;
        font-weight: normal;
        white-space: break-spaces;
      }
      .k-window-actions {
        position: absolute;
        left: -60px;
        background: $button-green-color;
        border-radius: 30px 0 0 30px;
        padding: 12px 12px 12px 18px;
        margin: -1em 0px;
        .k-icon {
          &::before {
            font-size: 20px;
            color: white;
          }
        }
      }
    }
    .k-window-content {
      padding: 0;
    }
  }
  .code-container {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 12px;
    background: #f7f7f7;
    position: relative;
  }
}
.vuln-modal {
  .k-pager-wrap{
    .k-label  {
      margin-bottom: 0px !important;
    } 
  }
} 

.k-list-item.k-selected,
.k-list-item.k-selected:hover {
  background-color: $button-green-color;
}

.filter-line {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 0 8px;
  // > .k-filter {
  //   height: 34px;
  //   display: flex;
  // }
}

.k-filter {
  display: block;
  > .k-filter-container {
    margin: 0;
  }
  .k-toolbar {
    .k-filter-field {
      .k-picker {
        width: 16em;
      }
    }
    .k-filter-value {
      .k-dropdownlist,
      .k-input {
        width: 15em;
      }
    }
  }
  .k-filter-group-main > .k-filter-toolbar {
    padding: 0;
    > .k-toolbar {
      border: none;
      padding: 0;
      > span {
        &:first-child,
        &:last-child,
        &:nth-child(3) {
          display: none;
        }
      }
      .k-button {
        padding: 8px;
        // max-height: 40px;
        // height: 40px;
      }
    }
  }
  .k-filter-lines .k-toolbar {
    background-color: inherit;
  }
}

// Исправления для kendo styles
.k-button:hover::before,
.k-button.k-hover::before,
.k-button-flat:focus::after,
.k-button-flat.k-focus::after {
  opacity: 0;
}
.k-drawer {
  border: none;
}
.k-drawer-item.k-state-selected {
  background-color: inherit;
}
.k-drawer-item .k-item-text {
  padding: 0;
}
.k-drawer-separator {
  height: 20px;
  background-color: inherit;
}
.k-drawer-item:focus,
.k-drawer-item.k-state-focused,
.k-input:focus,
.k-input.k-focus,
.k-picker:focus,
.k-picker.k-focus,
.k-checkbox:focus,
.k-checkbox.k-focus,
.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus,
.k-button-solid-base:focus {
  box-shadow: none;
}
.k-drawer-item.k-state-selected:hover,
.k-drawer-item.k-state-selected.k-state-hover,
.k-drawer-item:hover,
.k-drawer-item.k-state-hover,
.k-drawer-item:focus,
.k-drawer-item.k-state-focused {
  background-color: inherit;
}
.k-drawer-item.head {
  cursor: default;
  // font-size: 14px;
  font-size: 13px;
}
.k-menu-link {
  padding: 0;
}
.k-menu:not(.k-context-menu) > .k-item {
  color: inherit;
  &:hover {
    color: $button-green-color;
  }
}
col.k-sorted,
th.k-sorted {
  background-color: inherit;
}
.k-grid tr.k-alt {
  background-color: inherit;
}
.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
  color: inherit;
  background-color: inherit;
}
.k-pager-wrap {
  border: none;
  background-color: inherit;
  padding-bottom: 0;
  padding: 16px 8px;
  a {
    padding: 16px;
  }
  .k-pager-first,
  .k-pager-last {
    display: none;
  }
  .k-pager-numbers {
    // li + li {
    //   margin-left: 16px;
    // }
    gap: 0 20px;
    .k-link {
      color: $text-green-color;
    }
    .k-state-selected {
      color: $main-text-color;
      background-color: inherit;
    }
  }
}

.k-titlelayout {
  padding: 0;
}

.task__status {
  .success {
    color: $text-green-color;
  }
  .fail {
    color: #f20c0c;
  }
  .processing {
    color: #f49c0f;
  }
  .skipped{
    color: #666;
  }
}
.task__actions {
  display: flex;
  gap: 0 4px;
  align-items: center;
}
.k-cell-inner > .k-link > .k-icon.k-i-sort-desc-sm,
.k-cell-inner > .k-link > .k-icon.k-i-sort-asc-sm {
  // margin-left: 12px;
  margin-left: 4px;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  width: auto;
}
.k-tabstrip > .k-content {
  position: static;
}

.k-i-arrow-60-left {
  mask-image: url('../public/images/left_arrow_btn.svg');
  background: black;
  mask-repeat: no-repeat;
  mask-position: center;
  &::before {
    content: none;
  }
}
.k-i-arrow-60-right {
  mask-image: url('../public/images/right_arrow_btn.svg');
  background: black;
  mask-repeat: no-repeat;
  mask-position: center;
  &::before {
    content: none;
  }
}
.k-i-arrow-60-down {
  mask-image: url('../public/images/down_arrow_btn.svg');
  background: black;
  mask-repeat: no-repeat;
  mask-position: center;
  &::before {
    content: none;
  }
}

th.k-header {
  .k-cell-inner {
    margin: 0;
    .k-link {
      padding: 0;
    }
  }
}

.k-form:not(.full-size),
.k-label:not(.full-size) {
  font-size: 13px;
}

.no-cursor {
  cursor: default;
  .k-link {
    cursor: default;
  }
}

.k-list-item-text{
  font-size: 13px;
}

.input-noborder-bottom{
  // border-bottom: none !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0 0 0 0 !important;
  border-left: 0px !important;
  border-right: 0px !important;
  height: 32px !important;
  font-size: 12px !important;
  padding-left: 12px !important;
}
.button-noborder{
  border: none !important;
  padding: 0px !important;
  margin: 0px 0px 0px 2px !important;
  font-size: inherit;
  &:hover{
    background-color: inherit !important;
  }
}
.button-add-tag{
  width: 100%;
  height: 32px !important;
  border-radius: 0px !important;
  border: 0px !important;
  font-size: 10px !important;
  // margin-top: 8px;
  padding-left: 12px !important;
}
.button-noborder-space{
  margin-left: 0 !important;
}

.edit-tag-input{
  max-width: 208px !important;
  height: 24px !important;
  margin: 0 8px 0 0;
}
.common-group-actions{
  display: flex;
  margin: 12px 0 -12px 0;
  button:first-child{
    margin-right: 16px;
  }
}
.mb16{
  margin-bottom: 16px !important;
}
.mb24{
  margin-bottom: 20px;
}
.cred-in-add-form{
  color: #333;
}

.k-animation-container.k-animation-container-relative {
  z-index: 11000 !important;
}

.software-info-modal{
  h4{
    margin-bottom: 8px;
  }
  table.column td{
    vertical-align: middle;
  }
}

.content.soft-management table thead tr:hover{
  background-color: white;
}

// // .k-drawer-container {
// //   overflow: auto !important;
// // }
// .k-drawer-content{
//   overflow: auto;
// }

// body {
//   font-family: $font-family;
//   background-color: $background-color;
//   font-weight: 500;
//   // background-color: red;
//   // &:-webkit-full-screen {
//   //   background-color: red;
//   //   height: 10%;
//   // }
//   // &:fullscreen {
//   //   background-color: red;
//   //   height: 10%;
//   // }
// }
// body:-moz-full-screen {
//   background-color: red;
// }
// body:-webkit-full-screen {
//   background-color: red;
// }
// body:fullscreen {
//   background-color: red;
// }

// // Хедер контента готов
// .common-header-page {
//   background-color: rgba(242, 242, 242, 0.4);
//   border-bottom: 1px solid $pager-border-color;
//   padding: 2px 2px 2px 40px;
//   font-size: 27px;
//   font-weight: 700;
//   [class$='__topactions'] {
//     display: flex;
//     align-items: center;
//     margin-left: -8px;
//   }
//   .k-h3 {
//     padding-top: 28px;
//     background-color: rgba(255, 255, 255, 0);
//     box-sizing: border-box;
//     font-family: 'Arial Обычный', 'Arial', sans-serif;
//     font-weight: 700;
//     color: #555555;
//     text-align: left;
//     line-height: normal;
//     .k-icon {
//       margin-right: 16px;
//     }
//   }
//   .k-widget,
//   .k-button {
//     border: 0;
//     border-radius: 0;
//     font-size: 14px;
//     padding: 4px 8px;
//     font-weight: normal;
//     .k-menu-link-text,
//     .k-button-text {
//       color: #333333;
//     }
//     .k-icon {
//       color: #aaaaaa;
//     }
//     * {
//       padding: 0;
//     }
//   }
// }

// .k-content {
//   .k-animation-container {
//     overflow: visible;
//   }
//   .k-child-animation-container {
//     overflow: visible;
//   }
// }

// .k-grid {
//   border: none;
//   color: #4b4b4b;
//   font-size: 14px;
//   background-color: inherit;
//   .k-toolbar {
//     border: none;
//     color: inherit;
//     background: inherit;
//     padding-left: 0;
//   }
//   table {
//     @extend %table
//         /*
//     border-collapse: collapse;
//     thead {
//       background-color: inherit;
//     }
//     tbody{
//       background-color: inherit;
//     }
//     td, th{
//       padding: 0;
//     }

//      */;
//   }
//   /*

//   td, th{
//     padding: 0 0 0 16px;
//   }
//   .k-grid-header {
//     background-color: inherit;
//     border-color: $split-table-color;
//     font-size: 11px;
//     color: #7f7f7f;
//     th{
//       padding-bottom: 8px;
//     }
//     .k-grid-header-wrap{
//       border: none;
//     }
//   }
//   .k-grid-content{
//     background-color: inherit;
//     overflow-y: visible;
//   }
//   .k-grid-table{
//     border-collapse: collapse;
//     // min-height: 100px;
//   }
//   th, td{
//     text-align: left;
//     border: none;
//   }
//   th.k-sorted, td.k-sorted, col.k-sorted{
//     background-color: inherit;
//   }

//    */
//   .k-master-row,
//   .k-master-row.k-alt {
//     background-color: inherit;
//     border-bottom: solid 1px $split-table-color;
//   }
//   .k-pager-wrap {
//     margin-top: 16px;
//     border: none;
//     background-color: inherit;
//     color: $pager-text-color;
//     .k-pager-first,
//     .k-pager-last {
//       display: none;
//     }
//     .k-pager-numbers {
//       border: solid 1px $pager-border-color;
//       .k-link {
//         color: inherit;
//       }
//       .k-state-selected {
//         background-color: $red-color;
//         color: $background-color;
//       }
//     }
//     .k-dropdownlist {
//       background-color: inherit;
//       color: inherit;
//       border-radius: 0;
//     }
//   }
// }

// .common-summary-page {
//   margin-right: $left-margin;
//   // margin-bottom: 16px;
//   padding: 16px 0 16px 0;
//   color: #333333;
//   font-size: 14px;
//   border-bottom: 1px solid rgba(197, 195, 195, 0.4);
//   display: flex;
//   justify-content: flex-start;
//   table[class^='column-'] + table[class^='column-'] {
//     margin-left: 88px;
//   }
//   table[class^='column-'] tbody {
//     max-width: 500px;
//     border-spacing: 0 16px;
//     .actions td:last-child {
//       @extend %actions-block;
//     }
//     .scope td:last-child {
//       @extend %assets-block;
//     }
//     tr td:first-child {
//       color: #7f7f7f;
//     }
//     tr td:nth-child(2) {
//       padding: 0 4px 0 20px;
//     }
//     td {
//       padding-top: 8px;
//       padding-bottom: 8px;
//     }
//   }
//   a {
//     color: inherit;
//   }
// }

// .dropdown__container {
//   position: relative;
//   &:hover .dropdown__content {
//     display: block;
//   }
//   .dropdown__content {
//     display: none;
//     position: absolute;
//     box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
//     background-color: $background-color;
//     color: rgb(85, 85, 85);
//     z-index: 1000;
//     background: $background-color;
//     .k-button {
//       padding: 4px 0;
//       width: 100%;
//       border-radius: 0;
//     }
//   }
//   // .dropdown__data{
//   //     font-size: 14px;
//   // }
//   .dropdown__data {
//     display: flex;
//     align-items: center;
//     .droptitle {
//       padding: 0 4px;
//     }
//   }
// }

// // .dropdown__content {
// //     display: none;
// //     // display: block;
// //     position: absolute;
// //     box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
// //     background-color: $background-color;
// //     // padding: 4px 8px;
// //     .k-button {
// //         padding: 4px 0;
// //         width: 100%;
// //         border-radius: 0;
// //     }
// // }

// .k-notification-group {
//   z-index: 9999;
// }

// .audit {
//   @extend %action;
//   background-color: #039511;
// }
// .audit-image {
//   @extend %action;
//   background-color: #0d7817;
//   // padding: 0 2px;
// }
// .audit-header {
//   @extend %action;
//   background-color: #039511;
//   font-size: 28px;
//   padding: 4px 8px;
// }

// .package-header {
//   @extend %action;
//   background-color: #794cf5;
//   font-size: 28px;
//   padding: 4px 8px;
// }

// .inventorization {
//   @extend %action;
//   background-color: #02a7f0;
// }

// .group-report {
//   @extend %action;
//   background-color: #ff9800;
// }

// .single-report {
//   @extend %action;
//   background-color: #ffc107;
// }

// .notification {
//   @extend %action;
//   background-color: #079696;
// }

// .agent-update {
//   @extend %action;
//   background-color: #3f51b5;
// }

// .package_update {
//   @extend %action;
//   background-color: #794cf5;
// }

// .inventorization-header {
//   @extend %action;
//   background-color: #02a7f0;
//   font-size: 28px;
//   padding: 4px 8px;
// }

// .k-filter-group-main > .k-filter-toolbar > .k-widget {
//   padding: 0;
//   border-radius: 12px;
//   span.k-icon {
//     width: 16px;
//     height: 16px;
//   }
//   button {
//     border: none;
//     background-color: inherit;
//   }
// }

// .low-color-text {
//   color: #00bb00;
// }

// .low-color-medium {
//   color: #ffb800;
// }

// .low-color-high {
//   color: #ff6200;
// }

// .low-color-critical {
//   color: #dc0000;
// }

// .k-notification-group {
//   z-index: 10003;
// }

// .k-grid > * .k-button {
//   user-select: text;
// }

// .k-i-computer {
//   content: url('../public/images/computer.svg');
//   margin-right: 16px;
//   height: 100%;
//   vertical-align: middle;
//   // width: 16px;
//   // height: 16px;
// }

// .k-i-task {
//   content: url('../public/images/task.svg');
//   margin-right: 16px;
//   height: 100%;
//   vertical-align: middle;
//   // width: 16px;
//   // height: 16px;
// }

.k-i-loader {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border-color: #4b4b4b;
  display: block;
  margin: 0 12px;
  position: relative;
  color: inherit;
  background: #4b4b4b;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

// // .btn-submit {
// //   background: #ff6358;
// //   color: white;
// //   &:hover {
// //     background: #db483d;
// //   }
// // }
// button[type='submit'] {
//   background-color: #ff6358;
//   cursor: pointer;
//   color: white;
//   &:hover {
//     background: #db483d;
//     // color: #424242;
//   }
// }

// // @keyframes form-animation{
// //     0%{
// //         right: -9999;
// //     }
// //     100%{
// //         right: 0;
// //     }
// // }
@keyframes animloader {
  0% {
    box-shadow: -8px 0 0 1px #4b4b4b, 0 0 0 1px #4b4b4b, 8px 0 0 1px #4b4b4b;
  }
  25% {
    box-shadow: -8px 0 0 2px #4b4b4b, 0 0 0 1px #4b4b4b, 8px 0 0 1px #4b4b4b;
  }
  50% {
    box-shadow: -8px 0 0 1px #4b4b4b, 0 0 0 2px #4b4b4b, 8px 0 0 1px #4b4b4b;
  }
  75% {
    box-shadow: -8px 0 0 1px #4b4b4b, 0 0 0 1px #4b4b4b, 8px 0 0 2px #4b4b4b;
  }
  100% {
    box-shadow: -8px 0 0 1px #4b4b4b, 0 0 0 1px #4b4b4b, 8px 0 0 1px #4b4b4b;
  }
}

// // .k-animation-container-shown{
// //     z-index: 10012 !important
// // }
// /*
// .tasklist-icon{
//   background: url("../public/images/tasklist_icon.svg");
// }
//  */

// .k-toolbar .k-input,
// .k-toolbar .k-picker {
//   width: 200px;
// }

// .custom-search {
//   width: 25%;
//   margin-bottom: 20px;
// }

// .underlined {
//   border-bottom: 1px dashed black !important;
//   line-height: 0.8rem;
// }

// .cvss-button {
//   background: inherit !important;
//   border: none;
//   padding: 0;
//   &:hover {
//     background: inherit;
//   }
// }

// .check-license {
//   // background: #ef664f;
//   padding: 16px;
//   color: white;
//   font-size: 14px;
//   margin: 8px 32px 0 0;
//   position: relative;
//   .k-button {
//     position: absolute;
//     top: 8px;
//     right: 12px;
//     .k-icon {
//       color: black;
//     }
//   }
// }

// .check-license-warning {
//   background: #f8d189;
//   color: rgb(41, 40, 40);
// }
// .check-license-error {
//   background: #ef664f;
// }
// .link-about-lic {
//   color: inherit;
//   font-weight: 300;
// }
.k-button-group {
  display: none;
}

// 28.07.2023
.accounts,
.server-list,
.user-list {
  .k-filter {
    margin-right: 16px;
  }
  .k-widget .k-toolbar {
    // span:nth-child(3) button {
    //   display: none;
    // }
  }
}
// 02.08.2022
.apikey-list, .whitelist {
  .k-grid {
    th:last-child {
      .k-link:hover{
        cursor: unset;
      }
    }
    td:last-child {
      width: 40px;
      position: relative;
      overflow: visible;
    }
  }
}
//07.08.2023
.apikey-description, .whitelist-description {
  .k-tabstrip {
    margin-top: 12px;
  }
}
.whitelist-description{
  .k-state-active{
    padding-bottom: 28px !important;
  }
}

// 18.08.2023
button {
  .k-button-icon {
    width: 16px;
    height: 16px;
  }
}

//24.08.2023
.error-common-field {
  color: #ef664f;
  margin-top: 8px;
}
.form-field-wrapper {
  margin-bottom: 24px;
  textarea {
    resize: none;
  }
}
.button-helper {
  margin-left: 8px;
  border: none;
  background: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
}
.user-list {
  th:nth-child(2) {
    .k-link,
    .k-link:hover {
      cursor: default;
    }
  }
}

// В EditCredentialForm используется кнопка "Применить" с type="button" для того чтобы можно было сохранять при редактировании
// не изменные поля, не используя логику: <Form -> onSubmit={handleSubmit}

// .form-button-type {
//   background-color: #ff6358;
//   color: #fff;
//   &:hover {
//     background-color: #ff6358;
//   }
// }

.default-btn{
  align-content: center !important;
  align-items: center !important;
  background: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 8px !important;
  color: #424242 !important;
  display: flex !important;
  font-size: 14px !important;
  gap: 0 8px !important;
  justify-content: center !important;
  padding: 8px 16px !important;
  transition: background .5s !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  &:hover:not(:disabled){
    background: #ebebeb !important;
    cursor: pointer !important;
    transition: background .5s !important;
  }
}

.check-credential-content{
  margin: -16px 0 20px 0;
}

.check-credential-success, .check-credential-false{
  // margin-top: 12px;
  .check-credential-success__content{
    span:nth-child(2) {
      margin-left: 8px;
    }
  }
}

.custom-type-search-active{
  background: #ededed;;
}
.required-label:after{
  content: "*";
  color: #ef664f;;
  margin-left: 4px;  
}
.required-label-btn:after{
  content: "*";
  color: #ef664f;
  margin-left: -2px;  
}

//grid-table, to change background for row in table.
.row-grid-table{
  td, a{
    color: #b3b4b9
  }
}
.audit-layer{
  display: block;
}

.create-linux-asset{
  .form-content{
    .textarea-field{
      margin-bottom: 16px;
    }
  }  
}
.result-check-ip{
  display: inline-block;
  margin: 8px 0 0 0;
  color: #e92e2e;
}

.tooltip-form-wrap {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  .titleHelper {
    font-size: 18px;
    color: #424242;
    vertical-align: baseline;
  }
}
.tooltip-input-text-wrap{
  font-size: 12px;
  padding: 4px;
  z-Index: 999;
  position: absolute;
  margin-top: -52px;
  margin-left: 8px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.08);
  // color: #424242;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.03), 0 4px 5px 0 rgba(0,0,0,.04);
}
.common-tooltip-wrap{
  font-size: 12px;
  padding: 4px;
  z-Index: 101;
  position: absolute;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.08);
  color: #424242;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.03), 0 4px 5px 0 rgba(0,0,0,.04);
  span{
    white-space: normal;
  }
}
.multiline-tooltip-wrap{
  font-size: 12px;
  z-Index: 101;
  position: absolute;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.08);
  color: #424242;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.03), 0 4px 5px 0 rgba(0,0,0,.04);
  display: flex;
  flex-direction: column;
  span{
    white-space: normal;
  }
  .content-wrap{
    .value-text{
      font-weight: bold;
    }
  }
}
.tooltip-question-images{
  margin-left: 4px;
}
.disabled-label {
  color: #898686;
}
.condition-margin-top{
  margin-top: 12px;
}
.host-list, .group-list, .sensor-list{
  .domainname-wrap{
    display: flex;
    align-items: center; 
    img{
      height: 13px;
      width: 13px;
      margin: 3px 0 0 8px;
      // margin-left: 8px;
    }
    .tag-image-wrap{
      cursor: pointer;
      img{
      height: 28px !important;
      width: 28px !important;
      margin: 0;
      }
    }
  }
}
.host-list, .image-list{
  .k-filter .k-toolbar .k-filter-field .k-picker {
    min-width: 15rem;
    width: auto !important;
  }
}
.no-pageable-k-grid{
  padding: 0 0 120px 0;
}
.maintenance-windows-list .no-pageable-k-grid{
  padding: 0 0 56px 0;
}

.k-colorgradient {
  background-color: #ededed;
  // margin: 6px 0 0 0;
}
.common-header-page{
  .k-animation-container{
    // position: relative !important;
    // background-color: #ededed !important;
    top: 252px !important;
    // left: 457px !important;
  }
}
.no-header-top-margin{
  margin-top: 0 !important;
}

.k-grid-header .k-header {
  position: static;
}

.partial-inv-info{
  font-size: 13px;
  color: #333;
  display: flex;
  align-items: center;
  &__content{
    display: flex;
    margin-right: -12px;
    img{
      width: 16px !important;
      height: 16px;
      margin-right: 4px;
    }
    span:nth-child(2){
      display: inline-block;
      margin-right: 4px;
      font-weight: bold;
    }
  }
}
.button-mr20{
  margin-right: 20px;
}
.button-ml20{
  margin-left: 20px;
}
.button-mt32{
  margin-top: 32px;
}
.button-pl0{
  padding-left: 0 !important;
}
.vector-modal div.k-dialog-title{
  white-space: pre-line;
}

.k-treelist-scrollable .k-grid-header th{
  position: static;
}

.modal-k-form-mt{
  margin-top: -24px !important
}

.user-list-for-form-search-mt28{
  margin-top: 28px;
}
.user-list-for-form-search-mt8{
  margin-top: 8px;
}
.whiteListTitle{
  display: flex;
  align-items: baseline;
}
.titleHelper{
  font-size: 24px;
}
.whitelistCheckboxWrapper {
  display: flex;
  flex-direction: column;
  input {
    border-color: rgba(0, 0, 0, 0.38);
  }
}
.whitelistCheckboxContent{
  span {
    margin-bottom: 12px;
  }
  margin: 0 0 4px 8px;
}
.whiteListMetablockWrapper {
  padding: 16px 24px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 24px;  
}
.assetTypeTitle{
  margin-bottom: 12px;
}
.assetTypeContent{
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  span {
    margin-bottom: 12px;
  }
}
.whiteListMetablock {
  // padding: 16px 24px;
  display: flex;
  grid-gap: 0 60px;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 8px;  
}
.topActions{
  margin-bottom: 24px;
}
.whiteListDescription{
  margin-top: -8px;
  display: flex;
  span:first-child{
    // display: inline-block;
    margin-right: 8px;
  }
  span:nth-child(2){
    color: #666666;
  }
}
.whiteListAddForm {
  width: 1032px;
}
.AddVulnToWLForm {
  .formContent{
    margin-top: -28px !important;
  }
  width: 1180px;
  :global(.apply-assets){
    margin: 20px 0 0px 0 !important;
  }
}
.formContent {
  padding-top: 4px;
}
.formFieldWidth {
  max-width: 540px;
  margin-bottom: 16px;
}
.restrictions {
  display: flex;
  gap: 0 44px;
  align-items: center;
}
.restrictionsDisable {
  color: #c4c4c4;
}
.titleTabInfo {
  margin: 0 0 20px 0;
  color: #7f7f7f;
  max-width: 680px;  
}
.titleTabMain {
  display: flex;
  flex-direction: column;
  margin: 0 0 -12px 0;
  max-width: 680px;
  min-height: 38px;
  span:nth-child(2){
    color: #7f7f7f;
  }
}
.titleTabInfo {
  span {
    font-style: italic;
  }
}
.titleDescriptionList {
  margin-top: 20px;
  padding-left: 4px;
  color: #7f7f7f;
}
.descriptionTitleContent {
  font-size: 22px;
  margin: 24px 0;
  color: #555;
  font-weight: 600;
}
.whiteListFilterLine{
  margin-top: 12px;
  display: flex;
  :global(.k-filter){
    margin-right: 12px;
  };
  :global(.whitelist-filter){
    max-width: 400px;
  }
}
.whiteListScope{
  margin: 0 !important;
  :global(.k-tabstrip:not(.asset-tabstrip) .k-content.k-state-active) {
    border-bottom: none !important;
    border-radius: 0;
    padding-bottom: 0 !important;
  }
  :global(.k-tabstrip-items-wrapper){
    margin-left: 24px;
  }
  :global(.k-tabstrip) {
    margin-top: 4px;
  }
}
.formContent{
  :global(.apply-assets) {
    margin-bottom: 24px !important;
  }
  :global(.title-mutable-block:after){
    content: "*";
    color: #ef664f;;
    margin-left: 4px;
  }
}
.checkboxWrapperCount{
  display: flex;
  align-items: baseline;
  span:first-child{
    width: 220px;
  }
}
.checkboxCount{
  margin-left: 8px;
}
:global(.whitelist){
  table{
    td:nth-child(5){
      text-align: right;
      padding-right: 24px;
    }
  }
}
.usedAssetList{
  list-style-type: none;
  margin: 0;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 312px;
}
.apiDescriptionField{
  margin-top: -20px;
  max-width: 540px;
}
.mt{
  margin-top: 32px;
}
.mb{
  margin-bottom: 24px;
}
.checkboxMb{
  margin-bottom: 2px !important;
}
.softwareListForFormTabWrap{
  max-width: 1640px;
}
.tabStripWrap{
  margin-top: 4px;

}


@import '../../../vars';

.repositories_table {
    > table > thead {
      display: none;
    }
    > table > tbody {
      > tr {
        &:hover {
          background-color: inherit !important;
        }
        > td {
          border: none !important;
          padding: 0;
        }
      }
    }
  }

.status_image{
    width: 16px;
    height: 16px;
    display: inline-block;
    mask-size: cover;
    background-color: $table-text-color;
    background-color: #ccc;
    &.not_loaded, &.not_approved{
      background-color: #ccc;
    }
}

.download_onprem.downloaded,
 .download_onprem.not_loaded {
    mask-image: url('../../../../public/images/download-icon.svg');
}
.not_approved {
  mask-image: url('../../../../public/images/approve-icon.svg');
}
.approved {
    mask-image: url('../../../../public/images/approve-success-icon.svg');
    background-color: $green;
    &:hover{
      background-color: #999;
    }
}
.clickable:hover {
  cursor: pointer;
  background-color: #999;
}
.pending{
  background-color: #e1970a;
}
.error{
  mask-image: url('../../../../public/images/error_icon.svg');
  background-color: #d11111;
}
.downloaded{
  background-color: $green;
  
}
.succeeded{
    background-color: $green;
}
.availableUpdatesModal{
  max-width: 1120px !important;
  :global(.wrapper-menu .content){
    padding: 0 !important;
    border: none !important;
    margin-left: -12px;
  }
  table:first-child{
    padding: 0 !important;
    border: none !important;         
    :global(.wrapper-menu){
      border: none !important;
    } 
  }
}
.softwareAvailableUpdatesTable{
  :global(.wrapper-menu .content){
    padding: 0 !important;
    border: none !important;
    margin-left: -12px;
  }
  table:first-child{
    padding: 0 !important;
    border: none !important;         
    :global(.wrapper-menu){
      border: none !important;
    } 
  }
}

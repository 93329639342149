.cron_block{
    position: absolute;
    //   width: fit-content;
    box-sizing: border-box;
    width: 100%;
    //   height: '200px';
    border: 1px solid #ccc;
    background: white;
    z-index: 2;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
    padding: 16px 24px;
    //   right: 4px;
      margin-top: 12px;
}
.cron_block::after{
    content: ''; 
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent; 
    border-bottom: 10px solid #ccc;
    position: absolute; 
    // right: 12px;
    left: 160px; 
    bottom: 100%;
}

.cron_tab{
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 16px;
}
.cron_property{
    height: 34px;
    display: flex; 
    align-items: center;
    gap: 4px;
}
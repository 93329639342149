.ticketMetablockWrap{
  padding: 16px 24px;
  border: 1px solid #ccc;
  border-radius: 5px;    
  margin-bottom: 24px;
  :global(.k-progressbar){
    background-color: white !important;
    border: 1px solid #c5c5c5;
  }
  .progressBar{
    max-width: 86px;
    height: 18px;
    :global(.k-state-selected) {
      color: white;
      background-color: #44c74f;;
    }
  }  
}
.ticketMetablock{
  display: flex;
  grid-gap: 0 60px;
  flex-wrap: wrap;
  align-items: baseline;
}
.ticketMetablockDesc{
  word-break: break-all;
  .ticketMetablockTitle{
    color: #333;
  }
  .ticketMetablockContent{
    color: #666;
    padding-left: 8px;
  }
}
.radioWrap {
  margin-bottom: 4px;
}
.deploymentMethodLabelWrap{
  display: flex;
  position: relative;
  .titleRb{
    display: inline-block;
    font-weight: bold;
    margin-right: 8px;
  }
}
.statusMappingBlock{
  margin-left: 2px !important;
  .labelWrap{
    display: flex;
    position: relative;
  }
  .ticketStatusWrap{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 550px;
    margin: 0 0 8px 12px;  
  }
  .dropDownList{
    width: 400px;
  }
}
.open{
  color: #b32323;
  fill: #b32323;
}
.in-progress{
  color: orange;
  &:hover{
    .spanIcon{
      background-color: #333 !important;
    }
  }
}
.in-review{
  color: #2f01ad;
}
.closed{
  color: #039511;
}
.undefined{
  color: black;
}
.statusIcon{
  width: 16px;
  height: 16px;
}
.openMenu{
  :global(.k-menu) {
    border: 2px solid #b32323;
    :global(.k-menu-link-text){
      color: #b32323 !important;
    }    
    :global(.k-i-arrow-60-down){
      background: #b32323 !important;
    }
    :global(.k-i-k-icon){
      background: none !important;
      color: #b32323 !important;
    }
  }
}
.in-progressMenu{
  :global(.k-menu) {
    border: 2px solid orange;
    :global(.k-menu-link-text){
      color: orange !important;
    }    
    :global(.k-i-arrow-60-down){
      background: orange !important;
    }
    :global(.k-i-k-icon){
      background: orange !important;
      color: orange !important;
      &:hover{
        background-color: #333 !important;
      }
    }
  }
}
.in-reviewMenu{
  :global(.k-menu) {
    border: 2px solid #2f01ad;
    :global(.k-menu-link-text){
      color: #2f01ad !important;
    }    
    :global(.k-i-arrow-60-down){
      background: #2f01ad !important;
    }
    :global(.k-i-k-icon){
      background: none !important;
      color: #2f01ad !important;
    }
  }
}
.closedMenu{
  :global(.k-menu) {
    border: 2px solid #039511;
    :global(.k-menu-link-text){
      color: #039511 !important;
    }    
    :global(.k-i-arrow-60-down){
      background: #039511 !important;
    }
    :global(.k-i-k-icon){
      background: none !important;
      color: #039511 !important;
    }
  }
}
.cellStatusTicket{
  display: flex;
  align-items: center;
  .cellStatusTicketWrap{
    display: flex;
    align-items: center;
    .statusContentWithIcon{
      display: flex;
      align-items: center;
    }
  }
  :global(.k-menu){
    border: none;
    padding: 0;
  }
  :global(.k-menu-link-text){
    font-size: 13px;
  }
  .loaderWrap{
    margin-left: 8px;
    .statusLoader{
      color: #868484;
    }
  }
  // &.pointer{
  //   cursor: pointer;
  // }
  // &.inherit{
  //   cursor: inherit;
  // }
}
.ticketStatusValueWrap{
  display: flex;
  align-items: center;
  img{
    margin-left: 8px;
  }
}
.spanIcon{
  font-size: 16px;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  width: 16px;
  height: 16px;
  &.inProgress{
    mask-image: url('../../../../../public/images/ticketInProgress.svg');
    background-color: orange;
  }
}
.spanContent{
  display: inline-block;
  margin-left: 8px;
  display: 'inline-block';
  &.textDecorationUnderline{
    text-decoration: underline;
  }
  &.textDecorationNone{
    text-decoration: none;
  }
  &.textDecorationStyleDotted{
    text-decoration-style: dotted;
  }
  &.textDecorationStyleInherit{
    text-decoration-style: inherit;
  }
}
.spanProgressBar{
  display: inline-block;
  margin-left: 4px;
}
.gridProgressBar{
  max-width: 186px;
}
.gridTicketDescriptionActions{
  :global(.k-progressbar){
    background-color: white !important;
    border: 1px solid #c5c5c5;
  }
  :global(.k-state-selected) {
    color: white;
    background-color: #44c74f;;
  }
}

.critical {
  color: #f20c0c;
  fill: #f20c0c;
}
.high {
  color: #ff6200;
  fill: #ff6200;
}
.medium {
  color: #f79009;
  fill: #f79009;
}
.low {
  color: #12b76a;
  fill: #12b76a;
}
.none {
  color: #999;
  fill: #999
}
.uncompleted, .impossible {
  color: #f49c0f;
}
.completed {
  color: #00850b;
}

.ticketPriorityWrap{
  display: flex;
  align-items: center;
  .spanPriority{
    display: inline-block;
    margin-left: 4px;
  }
}
:global(.k-i-gear) {
  opacity: 0.6;
}
.ticketDesStatusWrap{
  display: flex;
  align-items: center;
  .statusLoader{
    color: #868484;
    margin-left: 4px;
  }
  :global(.k-loader-canvas){
    height: 12px !important;
    width: 12px !important;
  }
}
.tabCommon{
  font-size: 13px;
  &.noDescription{
    color: #666666;
    height: inherit;
  }
  .tabMinHeigh{
    padding: 24px 8px 0px 6px !important;
    overflow: hidden;
    overflow-wrap: break-word;
  } 
  .tabMaxHeigh{
    padding: inherit;
    overflow: auto;
    overflow-wrap: break-word;
  }
}
.tabsTicketDescription{
  display: flex;
  justify-content: space-between; 
  width: 100%;
  .leftTabGridWrap{
    width: 65%;
  }
  .rightWrap{
    margin: 2px 0 0 20px;
    width: 35%;
  }
}
.arrowWrap{
  margin: 0;
  text-align: center;
}
.weightTitle{
  font-weight: bold;
}

.cursorPointer{
  cursor: pointer;
}
.cursorInherit{
  cursor: inherit;
}

